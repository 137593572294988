import { gql } from "@apollo/client";

export const LOGIN_USER_MICROSOFT = gql`
  mutation LoginUserMicrosoft($token: String!) {
    loginUserMicrosoft(token: $token) {
      user {
        _id
        first_name
        last_name
        email
        handedness
        handicap
        registration_date
        last_access
        last_update
        active
        admin
      }
      accessToken
      refreshToken
    }
  }
`;
