import React, { useContext } from "react";
import "./SwingAreaDialog.scss"; // Your custom styles
import {
  Grid,
  DialogTitle,
  DialogContent,
  Typography as MuiTypography,
  Divider as MuiDivider,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import MeasurementImage from "../dashboard/goals/MeasurementImage";
import { GET_MEASUREMENT_VIDEOS } from "../../graphql/queries/getVideosByMeasurementId";
import { useQuery } from "@apollo/client";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StoreContext } from "../../utils/Store";
import { VideoComponent } from "../../utils/media";
import BallToggle from "../swingreport/BallToggle";

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

function SwingAreaDialog() {
  const { dialogMeasurement, setDialogMeasurement } = useContext(StoreContext);

  const dismissMeasurementDialog = () => {
    setDialogMeasurement(null);
  };

  const { data } = useQuery(GET_MEASUREMENT_VIDEOS, {
    variables: { measurementId: dialogMeasurement?._id },
    skip: !dialogMeasurement || !dialogMeasurement?._id,
  });

  const videos = data?.getVideosByMeasurementId ?? [];

  if (!dialogMeasurement) {
    return null;
  }

  const feature = dialogMeasurement.feature || null;
  const currentValue = feature?.value.toFixed(2) || 0;
  const currentValueFloat = parseFloat(currentValue);
  const minValue = dialogMeasurement.range_min.toFixed(2) || 0;
  const minValueFloat = parseFloat(minValue);
  const maxValue = dialogMeasurement.range_max.toFixed(2) || 0;
  const maxValueFloat = parseFloat(maxValue);
  const minOptimalValue = dialogMeasurement.optimal_min.toFixed(2) || 0;
  const minOptimalValueFloat = parseFloat(minOptimalValue);
  const maxOptimalValue = dialogMeasurement.optimal_max.toFixed(2) || 0;
  const maxOptimalValueFloat = parseFloat(maxOptimalValue);
  const rageOptimal = dialogMeasurement.optimal_label;
  const handicapImpact = feature?.shap.toFixed(2) || 0;

  return (
    <>
      <div
        className="dialog-overlay open"
        onClick={dismissMeasurementDialog}
      ></div>
      {/* Overlay for dimming the background */}
      <div className="swing-area-pane open">
        {/* Using DialogTitle from MUI */}
        <DialogTitle id="draggable-dialog-title" className="dialog-title">
          <Typography component="div">Improvement Area</Typography>
          <Typography
            component="div"
            style={{
              margin: 0,
              width: "100%",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {dialogMeasurement.title}
          </Typography>
          <IconButton
            onClick={dismissMeasurementDialog}
            className="closeButton"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider my={0} />
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12} md={7}>
              <MeasurementImage
                picture={dialogMeasurement.picture}
                stage={dialogMeasurement.stage}
                isPopup={true}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <div>
                {currentValueFloat < minOptimalValueFloat && (
                  <Typography style={{ fontSize: "1.1rem" }}>
                    {dialogMeasurement.description_low}
                  </Typography>
                )}
                {currentValueFloat > maxOptimalValueFloat && (
                  <Typography style={{ fontSize: "1.1rem" }}>
                    {dialogMeasurement.description_high}
                  </Typography>
                )}
                {currentValueFloat >= minOptimalValueFloat &&
                currentValueFloat <= maxOptimalValueFloat ? (
                  <Typography style={{ fontSize: "1.1rem" }}>
                    Your performance is in the optimal range.
                  </Typography>
                ) : null}
                <Typography
                  style={{
                    fontSize: "1.1rem",
                    marginTop: "1em",
                    marginBottom: "1em",
                  }}
                >
                  {dialogMeasurement.description}
                </Typography>
              </div>
              <div>
                <Typography style={{ fontSize: "1rem", paddingTop: "6px" }}>
                  <span style={{ color: "#315a98", fontWeight: 600 }}>
                    Your Value:
                  </span>{" "}
                  <span style={{ fontWeight: 500 }}>{currentValue}</span>
                  <br />
                  <span style={{ color: "#315a98", fontWeight: 600 }}>
                    Ideal range:
                  </span>{" "}
                  <span style={{ fontWeight: 500 }}>{rageOptimal}</span> <br />
                  <span style={{ color: "#315a98", fontWeight: 600 }}>
                    Handicap Impact:
                  </span>{" "}
                  <span style={{ fontWeight: 500 }}>{handicapImpact}</span>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <BallToggle
                currentValue={currentValue}
                minValue={minValue}
                maxValue={maxValue}
                minOptimalValue={minOptimalValue}
                maxOptimalValue={maxOptimalValue}
              />
            </Grid>
          </Grid>
          {videos.length > 0 && (
            <Typography variant="h4" sx={{ mt: 6, mb: -2 }}>
              Learn How To Fix
            </Typography>
          )}
          {videos.map((video) => (
            <>
              <Divider sx={{ mt: 4, mb: 4 }} />
              <Grid container spacing={6} key={video._id}>
                <Grid item xs={12} md={7}>
                  <VideoComponent
                    componentKey={video._id}
                    style={{ width: "100%" }}
                    swingId="videos"
                    fileName={video.video}
                    screenshot={video.screenshot}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Typography variant="h4" my={2} className="video-title">
                    {video.title}
                  </Typography>
                  <Typography variant="pVideo">{video.description}</Typography>
                </Grid>
              </Grid>
            </>
          ))}
        </DialogContent>
      </div>
    </>
  );
}

export default SwingAreaDialog;
