import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation LoginUser($input: LoginInput!) {
    loginUser(input: $input) {
      user {
        _id
        first_name
        last_name
        email
        handedness
        handicap
        registration_date
        last_access
        last_update
        active
        admin
      }
      accessToken
      refreshToken
    }
  }
`;
