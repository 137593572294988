// BallToggle.js
import React from "react";
import "./BallToggle.scss"; // Make sure your SCSS file reflects the new styles

const BallToggle = ({
  currentValue,
  minValue,
  maxValue,
  minOptimalValue,
  maxOptimalValue,
}) => {
  // Calculate the percentage positions of the ball and the optimal range
  let realCurrentValue = parseFloat(currentValue);
  let realMinValue = parseFloat(minValue);
  let realMaxValue = parseFloat(maxValue);
  let realMinOptimalValue = parseFloat(minOptimalValue);
  let realMaxOptimalValue = parseFloat(maxOptimalValue);

  if (realMinOptimalValue < realMinValue) {
    realMinValue = realMinOptimalValue;
  }

  if (realMaxOptimalValue > realMaxValue) {
    realMaxValue = realMaxOptimalValue;
  }

  if (realCurrentValue < realMinValue) {
    realMinValue = realCurrentValue;
  }

  if (realCurrentValue > realMaxValue) {
    realMaxValue = realCurrentValue;
  }

  const ballPositionPercent =
    ((realCurrentValue - realMinValue) / (realMaxValue - realMinValue)) * 100;
  const optimalStartPercent =
    ((realMinOptimalValue - realMinValue) / (realMaxValue - realMinValue)) *
    100;
  const optimalEndPercent =
    ((realMaxOptimalValue - realMinValue) / (realMaxValue - realMinValue)) *
    100;

  return (
    <div className="ballToggleContainer">
      <div className="gauge">
        <div
          className="gauge__range gauge__range--optimal"
          style={{
            left: `${optimalStartPercent}%`,
            right: `${100 - optimalEndPercent}%`,
          }}
        >
          <span className="optimal-min-value">{realMinOptimalValue}</span>
          <span className="optimal-max-value">{realMaxOptimalValue}</span>
        </div>
        <div
          className="gauge__ball"
          style={{ left: `${ballPositionPercent}%` }}
        />
        <span className="min-value">{realMinValue}</span>
        <span className="max-value">{realMaxValue}</span>
      </div>
    </div>
  );
};

export default BallToggle;
